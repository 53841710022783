import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../css/_bespoke-box.scss';

export default function BespokeBox() {
  const data = useStaticQuery(graphql`
    {
      sanitySiteInfo {
        bespoke {
          title
          subheading
          text
          image {
            alt
            asset {
              gatsbyImageData
            }
          }
        }
        contact {
          address
          email
          phone
        }
      }
    }
  `);
  const { bespoke } = data.sanitySiteInfo;
  const { contact } = data.sanitySiteInfo;
  const ph1 = contact.phone.slice(0, 2);
  const ph2 = contact.phone.slice(2, 5);
  const ph3 = contact.phone.slice(5, 8);
  const ph4 = contact.phone.slice(8, contact.phone.length);
  const fullPhone = `${ph1} (${ph2}) ${ph3} - ${ph4}`;
  return (
    <>
      <div className="wrapper">
        <div className="bespoke-box">
          <div className="content">
            <h3>{bespoke.title}</h3>
            <h4>{bespoke.subheading}</h4>
            <p>{bespoke.text}</p>

            <div className="btn-group">
              <button className="open-contact-modal" type="button">
                Book a free consultation
              </button>
              <div className="call-us">
                <p>or call us at</p>
                <a href={`tel:${contact.phone}`} className="underline white">
                  {fullPhone}
                </a>
              </div>
            </div>
          </div>
          <GatsbyImage
            className="pic"
            image={bespoke.image.asset.gatsbyImageData}
            alt={bespoke.image.alt}
          />
        </div>
      </div>
    </>
  );
}
