import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ProductSwiper from '../components/ProductSwiper';
import GallerySwiper from '../components/GallerySwiper';
import SectorSlider from '../components/SectorSlider';
import BespokeBox from '../components/BespokeBox';

import '../css/pages/_homepage.scss';
import QuoteIcon from '../images/ico-quote-gradient.svg';

export default function index({ data }) {
  const hero = data.sanityHomepage.hero1;
  const about = data.sanityHomepage.homeAbout;
  const testimonials = data.sanityHomepage.homeTestimonials;
  const allServices = data.allSanityService.nodes;
  const allSectors = data.allSanitySector.nodes;

  return (
    <Layout transparentNav>
      <SEO title="Connect Cayman" custom="Smart Home Automation Experts" />

      <div className="homepage">
        {/* Hero section */}
        <Hero
          type="type-2"
          heroImg={hero.image.asset.gatsbyImageData}
          heroImgAlt={hero.image.alt}
          h1={hero.title}
        />

        {/* Sector Boxes */}
        <section className="sector-boxes">
          <div className="wrapper">
            {allSectors.map((sector, i) => (
              <Link to={`/${sector.slug.current}`} className="box" key={i}>
                <img src={sector.icon.asset.url} alt={sector.title} />
                <h4>
                  {/* eslint-disable */
                  sector.title === 'Smart Home Automation'
                    ? 'Home'
                    : sector.title === 'Smart Business Solutions'
                    ? 'Business'
                    : sector.title
                  /* eslint-enable */}
                </h4>
                <p>{sector.short}</p>
              </Link>
            ))}
          </div>
        </section>

        {/* About us */}
        <section className="about-us">
          <div className="wrapper">
            <div className="images">
              <GatsbyImage
                className="img"
                image={about.aboutImage.asset.gatsbyImageData}
                alt={about.aboutImage.alt}
              />
            </div>
            <div className="content">
              <p className="big">{about.subheading}</p>
              <p className="regular">{about.para}</p>
              <div className="btn-group">
                <button className="open-contact-modal" type="button">
                  Book a free consultation
                </button>
                <Link to="about" className="link-2 white">
                  About us
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Our services */}
        <section className="our-services">
          <div className="wrapper">
            <h2>Our Services</h2>

            <div className="all-services">
              {allServices.map((service, i) => (
                <div className="service" key={i}>
                  <div className="images">
                    <GatsbyImage
                      image={service.mainImage.asset.gatsbyImageData}
                      alt={service.mainImage.alt}
                      className="thumbnail"
                    />
                    <img
                      src={service.icon.asset.url}
                      alt={service.title}
                      className="icon"
                    />
                  </div>
                  <h4 className="title">
                    <span className="underline">{service.title}</span>
                  </h4>
                </div>
              ))}
            </div>

            <div className="btn-container">
              <Link to="services" className="button">
                View all services
              </Link>
            </div>
          </div>
        </section>

        {/* Bespoke box */}
        <BespokeBox />

        {/* Usecases slider */}
        <SectorSlider autoplay />

        {/* Products */}
        <ProductSwiper />

        {/* Testimonials */}
        <div className="testimonials">
          <div className="pic">
            <GatsbyImage
              image={testimonials.reviewImage.asset.gatsbyImageData}
              alt={testimonials.reviewImage.alt}
              className="img"
            />
          </div>

          <div className="text">
            <div className="content">
              <img src={QuoteIcon} alt="Quotation" />
              <p className="review">{testimonials.review}</p>
              <div className="customer">
                <h4>{testimonials.customerName}</h4>
                <p>{testimonials.customerType}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        <GallerySwiper />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanityHomepage {
      hero1 {
        title
        image {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
      homeAbout {
        subheading
        para
        aboutImage {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
      homeTestimonials {
        review
        customerName
        customerType
        reviewImage {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
    }
    allSanityService(sort: { fields: order }, limit: 8) {
      nodes {
        title
        mainImage {
          alt
          asset {
            gatsbyImageData
          }
        }
        icon {
          asset {
            url
          }
        }
      }
    }
    allSanitySector(sort: { fields: order }) {
      nodes {
        title
        slug {
          current
        }
        short
        icon {
          asset {
            gatsbyImageData
            url
          }
        }
      }
    }
  }
`;
