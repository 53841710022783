import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import '../css/_swiper-edits.scss';
import RightArrow from '../images/ico-right-gradient.svg';
import SwipeIcon from '../images/ico-swipe.svg';

SwiperCore.use([Navigation]);

export default function GallerySwiper() {
  const [fade, setFade] = useState(false);

  function slideChange(swiper) {
    console.log(swiper);
    if (swiper.activeIndex > 0) {
      setFade(true);
    } else {
      setFade(false);
    }
  }

  const data = useStaticQuery(graphql`
    {
      sanitySiteInfo {
        galleryImgs {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const images = data.sanitySiteInfo.galleryImgs;

  return (
    <>
      <section className="gallery-section">
        <div className="wrapper">
          <Swiper
            id="gallery-swiper"
            spaceBetween={20}
            slidesPerView="auto"
            grabCursor
            navigation={{
              prevEl: '.prev-gallery',
              nextEl: '.next-gallery',
            }}
            onSlideChange={slideChange}
          >
            <SwiperSlide className="gallery-pic first" />
            {images.map((image, i) => (
              <SwiperSlide className="gallery-pic" key={i}>
                <GatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt={image.alt}
                  className="img"
                />
              </SwiperSlide>
            ))}

            {/* Heading */}
            <div
              className={`heading ${fade ? 'fade' : ''}`}
              slot="container-start"
            >
              <div className="title">
                <h2>Gallery</h2>
                <img src={SwipeIcon} alt="swipe" className="swipe-icon" />
              </div>
              <p>
                Take a look at our gallery of smart homes and offices and get
                inspired to start your project.
              </p>
            </div>

            {/* Navigation */}
            <div className="arrows">
              <div className="prev-gallery">
                <img src={RightArrow} alt="prev" />
              </div>
              <div className="next-gallery">
                <img src={RightArrow} alt="next" />
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
}
