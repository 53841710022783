import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from '@reach/router';
import gsap from 'gsap';
import '../css/_sliders.scss';

export default function SectorSlider({ autoplay = false }) {
  useEffect(() => {
    const slides = document.querySelectorAll('.sector-slides .slide');
    const slideBgs = document.querySelectorAll('.sector-imgs .slide-bg');
    const auto = autoplay;
    const intervalTime = 7000;
    let slideInterval;

    // Initialize
    slides[0].classList.add('current');
    slideBgs[0].classList.add('current');

    /* eslint-disable */
      function nextAnim() {
        const current = document.querySelector('.sector-slides .slide.current');
        const h3 = current.querySelector('h3');
        const desc = current.querySelector('.desc');
        const loadbar = current.querySelector('.line');
        const loading = current.querySelector('.line .progress');
        const SlideBg = document.querySelector('.sector-slider .sector-imgs');

        gsap.fromTo([h3, desc], { opacity: 0, y: 30 },
          {
            duration: 0.8,
            opacity: 1,
            y: 0,
            ease: 'power3.out',
            stagger: 0.12,
          }
        );
        gsap.fromTo(loadbar, { opacity: 0, y: 30 },
          {
            duration: 0.8,
            opacity: 1,
            y: 0,
            ease: 'power3.out',
          }
        );
        gsap.fromTo(loading, { height: 0 },
          {
            duration: intervalTime / 1000,
            height: '100%',
            ease: 'none',
          }
        );
        gsap.fromTo(SlideBg, { opacity: 0 },
          {
            duration: 1.2,
            opacity: 1,
          }
        );
        gsap.fromTo(SlideBg, { scale: 1 },
          {
            duration: intervalTime / 1000,
            scale: 1.05,
          }
        );
        // console.log('Sector slide changing!');
      }
      /* eslint-enable */

    nextAnim(); // for the first time when it loads

    const nextSlide = () => {
      const current = document.querySelector('.sector-slides .slide.current');
      current.classList.remove('current');
      const currentImg = document.querySelector(
        '.sector-imgs .slide-bg.current'
      );
      currentImg.classList.remove('current');

      if (current.nextElementSibling) {
        current.nextElementSibling.classList.add('current');
        currentImg.nextElementSibling.classList.add('current');
      } else {
        slides[0].classList.add('current');
        slideBgs[0].classList.add('current');
      }
      setTimeout(() => {
        current.classList.remove('current');
        currentImg.classList.remove('current');
      });

      nextAnim();
    };

    const resetAutoAnim = () => {
      if (auto) {
        clearInterval(slideInterval);
        slideInterval = setInterval(nextSlide, intervalTime);
      }
    };

    slides.forEach((slide, i) => {
      slide.addEventListener('click', () => {
        const current = document.querySelector('.sector-slides .slide.current');
        current.classList.remove('current');
        const currentImg = document.querySelector(
          '.sector-imgs .slide-bg.current'
        );
        currentImg.classList.remove('current');

        if (!slide.classList.contains('current')) {
          slide.classList.add('current');
          slideBgs[i].classList.add('current');
        }

        nextAnim();
        resetAutoAnim();
      });
    });

    if (auto) {
      slideInterval = setInterval(nextSlide, intervalTime);
    }

    return () => {
      clearInterval(slideInterval);
    };
  });

  const data = useStaticQuery(graphql`
    {
      allSanitySector(sort: { fields: order }) {
        nodes {
          title
          slug {
            current
          }
          short
          mainImage {
            alt
            asset {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `);
  const allSectors = data.allSanitySector.nodes;

  return (
    <>
      <div className="sector-slider">
        <div className="sector-imgs">
          {allSectors.map((sector, i) => (
            <div className="slide-bg" key={i}>
              <GatsbyImage
                image={sector.mainImage.asset.gatsbyImageData}
                alt={sector.mainImage.alt}
                className="img"
              />
            </div>
          ))}
        </div>

        <div className="wrapper">
          <div className="sector-slides">
            {allSectors.map((sector, i) => (
              <div className="slide" key={i}>
                <h3>{sector.title}</h3>
                <div className="desc">
                  <p>{sector.short}</p>
                  <Link to={`/${sector.slug.current}`} className="link-2">
                    Learn more
                  </Link>
                </div>
                <div className="line">
                  <div className="progress" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
